import React, { useEffect, useState } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'

function LineChartComponent({ data = [], config = {}, onRendered = () => {} }) 
{
    const [isLoading, setIsLoading] = useState(true)

    // chart rendering  
    const [root, setRoot] = useState(null)
    const [legendRoot, setLegendRoot] = useState(null)
    const [chart, setChart] = useState(null)
    const [xAxis, setXaxis] = useState(null)
    const [yAxis, setYaxis] = useState(null)

    // chart display data nad additional configurations
    const [chartData, setChartData] = useState([])
    const [chartConfig, setChartConfig] = useState({})
    const [legendWidth, setLegendWidth] = useState('1000px')
    
    // create series from new data
    const createSeries = (point, index) => {
        const data = JSON.parse(JSON.stringify(chartData?.data))

        const mappedSeriesData = data.map((item) => {
            const dateObj = new Date(item.x.replace(/-/g, '/'))

            const newItem = {
                x: dateObj.getTime(),
                y: null
            }

            newItem.y = item?.g[index]
            return newItem
        })
        
        const series = chart.series.push( 
            am5xy.LineSeries.new(root, { 
                name: point?.title,
                xAxis: xAxis, 
                yAxis: yAxis, 
                valueYField: 'y', 
                valueXField: 'x',
                stacked: false
            }) 
        )

        series.strokes.template.setAll({
            strokeWidth: 1
        })

        series.fills.template.setAll({
            fillOpacity: 0,
            visible: false
        })

        series.set("stroke", am5.color(point?.color))
        series.data.setAll(mappedSeriesData)
    }

    const getAxisConstraints = () => {
        if (chartConfig?.web_quick_graph?.user_scale)
        {
            return {
                min: chartConfig?.web_quick_graph?.user_scale?.minimum,
                max: chartConfig?.web_quick_graph?.user_scale?.maximum
            }
        }

        if (chartConfig?.web_quick_graph?.value_axes)
        {
            return {
                min: chartConfig?.web_quick_graph?.value_axes[0].minmum,
                max: chartConfig?.web_quick_graph?.value_axes[0].maximum
            }
        }

        let largest = -Infinity
        let smallest = Infinity

        for (const item of chartData.data) {
            if (Array.isArray(item?.g) && item?.g?.length > 0) {
                for (const value of item.g) {
                    if (value > largest) {
                        largest = value
                    }

                    if (value < smallest) {
                        smallest = value
                    }
                }
            }
        }

        return {
            min: smallest,
            max: largest
        }
    }

    // set the height of the chart based on the scale provided
    const addAutoHeight = () => {
        const constraints = getAxisConstraints()
        const minHeight = 300; 
        const maxHeight = 400; 
        const calculatedHeight = constraints?.max;
        const finalHeight = Math.min(maxHeight, Math.max(minHeight, calculatedHeight))
        
        chart.root.dom.style.height = finalHeight + "px"
    }

    let renderEventTimeout

    const notifyRendered = () => {
        if (renderEventTimeout) 
        {
            clearTimeout(renderEventTimeout)
        }

        renderEventTimeout = setTimeout(() => {
            onRendered()
            root.events.off('frameended')
        }, 100)
    }
    
    // recieve new data
    useEffect(() => {
        if (data && config)
        {
            const legendWidth = data[0]?.g.length * 150
            setIsLoading(true)
            setLegendWidth(`${legendWidth}px`)
            setChartData(data)
            setChartConfig(config)
        }
    }, [data, config])

    // create chartData
    useEffect(() => {
        am5.addLicense("AM5C337441252")

        const r = am5.Root.new("chartdiv")
        const l = am5.Root.new("legenddiv")

        if (
            chartConfig &&
            chartData &&
            chartData.data &&  
            chartData?.data?.length > 0
        ) 
        {    
            r.setThemes([
                am5themes_Animated.new(r)
            ])
    
            let c = r.container.children.push( 
                am5xy.XYChart.new(r, {
                    panY: false,
                    layout: r.verticalLayout,
                    paddingLeft: 0,
                }) 
            )
    
            const yConstraints =  getAxisConstraints()

            let yAxis = c.yAxes.push(
                am5xy.ValueAxis.new(r, {
                    maxDeviation: 1,
                    renderer: am5xy.AxisRendererY.new(r, {}),
                    min: yConstraints.min,
                    max: yConstraints.max
                })
            )

            let xAxis = c.xAxes.push(am5xy.DateAxis.new(r, {
                maxDeviation: 0.5,
                baseInterval: { timeUnit: "second", count: chartData?.min_resolution },
                renderer: am5xy.AxisRendererX.new(r, {
                    minGridDistance: 50, 
                    pan:"zoom"
                })
            }))

            setRoot(r)
            setLegendRoot(l)
            setChart(c)
            setXaxis(xAxis)
            setYaxis(yAxis)

            setTimeout(() => {
                setIsLoading(false)
            }, 500)
        }

        return () => {
            if (r) 
            {
                r.dispose()
                l.dispose()
            }
        }
    }, [chartData, chartConfig])

    // configure the chart
    useEffect(() => {
        if (chart)
        {
            root.events.on('frameended', notifyRendered)

            // create series
            chartConfig?.web_quick_graph?.settings?.points?.forEach((point, index) => {
                createSeries(point, index)
            })

            // create legend
            legendRoot.setThemes([
                am5themes_Animated.new(legendRoot),
                am5xy.DefaultTheme.new(legendRoot)
            ])

            const legend = legendRoot.container.children.push(am5.Legend.new(legendRoot, {
                layout: root.horizontalLayout
            }))

            legend.labels.template.setAll({
                fontSize: "12px",
                maxWidth: 100,
                oversizedBehavior: "truncate", 
                ellipsis: "...",
            })

            legend.data.setAll(chart.series.values)

            // configure xAxis
            xAxis.children.push(am5.Label.new(root, {
                text: chartConfig?.web_quick_graph?.settings?.x_axis?.title || '',
                textAlign: 'center',
                x: am5.p50,
                fontWeight: 'bold',
                fontSize: 12,
            }))

            xAxis.get("renderer").labels.template.setAll({
                oversizedBehavior: "wrap",
                textAlign: "center",
                fontSize: 10,
            })

            xAxis.get("dateFormats")["hour"] = 'hh:mm a'

            xAxis.get("renderer").labels.template.adapters.add("text", function(text, target) {
                if (target.dataItem && target.dataItem.dataContext) 
                {
                  return target.dataItem.dataContext.categoryLabel
                }

                if ( !/AM|PM/.test(text) )
                {
                    return `[bold]${text}[/]`
                }

                return text
            })

            // configure yAxis
            yAxis.children.unshift(am5.Label.new(root, {
                text: chartConfig?.web_quick_graph?.settings?.y_axis?.title || '',
                textAlign: 'center',
                y: am5.p50,
                rotation: -90,
                fontWeight: 'bold',
                fontSize: 12,
            }))

            yAxis.get("renderer").labels.template.adapters.add("text", function(text, target) {
                if (target.dataItem && target.dataItem.dataContext) 
                {
                  return target.dataItem.dataContext.categoryLabel
                }

                if (chartConfig?.web_quick_graph?.settings?.y_axis?.units) 
                {
                    return text + chartConfig?.web_quick_graph?.settings?.y_axis?.units
                }

                return text
            })

            yAxis.get("renderer").labels.template.setAll({
                oversizedBehavior: "wrap",
                textAlign: "center",
                fontSize: 10,
            })

            addAutoHeight()
        }

    }, [chart])
  
    return (
        <>
            <div className="chart-container" style={{ width: "100%", height: "100vh", position: 'relative' }}>
                <div id="chartdiv" style={{ width: "100%" }}></div>
                <div
                    style={{
                        width: '100vw',
                        margin: '0 auto',
                        overflowX: 'scroll',
                        overflowY: 'hidden'
                    }}
                >  
                    <div 
                        id="legenddiv"
                        style={{
                            height: '50px',
                            width: legendWidth,
                        }}
                    ></div>
                </div>
            </div>
        </>
    )
}

export default LineChartComponent