import React, { useEffect, useState } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'

function ColumnChartComponent({ 
    data = [], 
    config = {}, 
    onRendered = () => {} 
}) {
    const [isLoading, setIsLoading] = useState(true)

    // chart rendering  
    const [root, setRoot] = useState(null)
    const [legendRoot, setLegendRoot] = useState(null)
    const [chart, setChart] = useState(null)
    const [xAxis, setXaxis] = useState(null)
    const [yAxis, setYaxis] = useState(null)

    // chart display data nad additional configurations
    const [chartData, setChartData] = useState([])
    const [chartConfig, setChartConfig] = useState({})
    const [legendWidth, setLegendWidth] = useState('1000px')
    
    // create series from new data
    const createSeries = (point, index) => {
        const data = JSON.parse(JSON.stringify(chartData?.data))

        const mappedSeriesData = data.map((item) => {
            const dateObj = new Date(item.x.replace(/-/g, '/'))

            const newItem = {
                x: dateObj.getTime(),
                y: item?.g[index]
            }

            return newItem
        })

        const series = chart.series.push( 
            am5xy.ColumnSeries.new(root, { 
                name: `${point?.title}`,
                xAxis: xAxis, 
                yAxis: yAxis, 
                valueYField: 'y', 
                valueXField: 'x',
                stacked: chartConfig?.web_quick_graph?.settings?.y_axis?.stacked
            }) 
        )

        series.columns.template.setAll({
            width: chartConfig?.web_quick_graph?.settings?.y_axis?.stacked ? 15 : 5,
            fill: point?.color,
            stroke: point?.color
        })

        series.data.setAll(mappedSeriesData)
    }

    const getAxisConstraints = () => {
        if (chartConfig?.web_quick_graph?.user_scale)
        {
            return {
                min: chartConfig?.web_quick_graph?.user_scale?.minimum,
                max: chartConfig?.web_quick_graph?.user_scale?.maximum
            }
        }

        if (chartConfig?.web_quick_graph?.value_axes)
        {
            return {
                min: chartConfig?.web_quick_graph?.value_axes[0].minmum,
                max: chartConfig?.web_quick_graph?.value_axes[0].maximum
            }
        }

        let largest = -Infinity
        let smallest = Infinity

        for (const item of chartData.data) {
            if (Array.isArray(item?.g) && item?.g?.length > 0) {
                for (const value of item.g) {
                    if (value > largest) {
                        largest = value
                    }

                    if (value < smallest) {
                        smallest = value
                    }
                }
            }
        }

        return {
            min: smallest,
            max: largest
        }
    }

    let renderEventTimeout

    const notifyRendered = () => {
        if (renderEventTimeout) 
        {
            clearTimeout(renderEventTimeout)
        }

        renderEventTimeout = setTimeout(() => {
            onRendered()
            root.events.off('frameended')
        }, 100)
    }

    // set the height of the chart based on the scale provided
    const addAutoHeight = () => {
        const constraints = getAxisConstraints()
        const minHeight = 300; 
        const maxHeight = 400; 
        const calculatedHeight = constraints?.max;
        const finalHeight = Math.min(maxHeight, Math.max(minHeight, calculatedHeight))
        
        chart.root.dom.style.height = finalHeight + "px"
    }

    // recieve new data
    useEffect(() => {
        if (data && config)
        {
            const legendWidth = data[0]?.g.length * 150
            setIsLoading(true)
            setLegendWidth(`${legendWidth}px`)
            setChartData(data)
            setChartConfig(config)
        }
    }, [data, config])

    // create chartData
    useEffect(() => {
        am5.addLicense('AM5C337441252')

        const r = am5.Root.new('chartdiv')
        const l = am5.Root.new('legenddiv')

        if (
            chartConfig &&
            chartData &&
            chartData.data &&  
            chartData?.data?.length > 0
        ) 
        {    
            r.setThemes([
                am5themes_Animated.new(r)
            ])
              
            const c = r.container.children.push( 
                am5xy.XYChart.new(r, {
                    panY: false,
                    wheelY: "zoomX",
                    layout: r.verticalLayout,
                    paddingLeft: 0
                }) 
            )

            const yConstraints = getAxisConstraints()
  
            const yAxis = c.yAxes.push(
                am5xy.ValueAxis.new(r, {            
                    maxDeviation: 1,
                    maxHeight: 300,
                    min: yConstraints.min,
                    max: yConstraints.max,
                    renderer: am5xy.AxisRendererY.new(r, {})
                })
            )
        
            const xAxis = c.xAxes.push(
                am5xy.DateAxis.new(r, {
                    groupData: true,
                    maxDeviation: 0.5,
                    baseInterval: { timeUnit: data?.resolution, count: 1 },
                    renderer: am5xy.AxisRendererX.new(r, {
                        minGridDistance: 50, 
                        pan:"zoom"
                    })
                })
            )

            setRoot(r)
            setLegendRoot(l)
            setChart(c)
            setXaxis(xAxis)
            setYaxis(yAxis)

            setTimeout(() => {
                setIsLoading(false)
            }, 500)
        }

        return () => {
            if (r) 
            {
                r.dispose()
                l.dispose()
            }
        }
    }, [chartData, chartConfig])

    // configure the chart
    useEffect(() => {
        if (chart)
        {  
            root.events.on('frameended', notifyRendered)
    
            chartConfig?.web_quick_graph?.settings?.points?.forEach((point, index) => {
                createSeries(point, index)
            })

            // create legend
            legendRoot.setThemes([
                am5themes_Animated.new(legendRoot),
                am5xy.DefaultTheme.new(legendRoot)
            ])

            const legend = legendRoot.container.children.push(am5.Legend.new(legendRoot, {
                layout: root.horizontalLayout,
                y: am5.percent(50)
            }))

            legend.labels.template.setAll({
                fontSize: "12px"
            })

            legend.data.setAll(chart.series.values)

            // configure xAxis
            xAxis.children.push(am5.Label.new(root, {
                text: chartConfig?.web_quick_graph?.settings?.x_axis?.title || '',
                textAlign: 'center',
                x: am5.p50,
                fontWeight: 'bold',
                fontSize: 12,
            }))

            xAxis.get("renderer").labels.template.setAll({
                oversizedBehavior: "wrap",
                textAlign: "center",
                fontSize: 10,
            })

            xAxis.get("dateFormats")["hour"] = 'hh:mm a'

            xAxis.get("renderer").labels.template.adapters.add("text", function(text, target) {
                if (target.dataItem && target.dataItem.dataContext) 
                {
                  return target.dataItem.dataContext.categoryLabel
                }

                if ( !/AM|PM/.test(text) )
                {
                    return `[bold]${text}[/]`
                }

                return text
            })

            // configure yAxis
            yAxis.children.unshift(am5.Label.new(root, {
                text: chartConfig?.web_quick_graph?.settings?.y_axis?.title || '',
                textAlign: 'center',
                y: am5.p50,
                rotation: -90,
                fontWeight: 'bold',
                fontSize: 12,
            }))

            yAxis.get("renderer").labels.template.adapters.add("text", function(text, target) {
                if (target.dataItem && target.dataItem.dataContext) 
                {
                  return target.dataItem.dataContext.categoryLabel
                }

                if (chartConfig?.web_quick_graph?.settings?.y_axis?.units) 
                {
                    return text + chartConfig?.web_quick_graph?.settings?.y_axis?.units
                }

                return text
            })

            yAxis.get("renderer").labels.template.setAll({
                oversizedBehavior: "wrap",
                textAlign: "center",
                fontSize: 10,
            })

            if (yAxis?._settings?.max - yAxis?._settings?.min > 300)
            {
                yAxis.get("renderer").grid.template.set("forceHidden", true)
                yAxis.get("renderer").labels.template.set("forceHidden", true)

                const createRange = (value, axis, label) => {
                    let rangeDataItem = axis.makeDataItem({
                        value: value
                    })
                    
                    let range = axis.createAxisRange(rangeDataItem)
                    
                    range.get("label").setAll({
                        forceHidden: false,
                        text: label
                    })
                  
                    range.get("grid").setAll({
                        forceHidden: false,
                        strokeOpacity: 0.2,
                        location: 1
                    })
                }

                const startLabel = Math.floor(yAxis?._settings?.min / 100) * 100
                const endLabel = (Math.ceil(yAxis?._settings?.max / 100) * 100) + 100
                
                for (let i = startLabel; i < endLabel; i += 100)
                {
                    createRange(i, yAxis, `${i}`)
                }
            }

            addAutoHeight()
        }
    }, [chart])
  
    return (
        <>
            <div className="chart-container" style={{ width: "100%", height: "100vh", position: 'relative' }}>
                <div id="chartdiv" style={{ width: "100%", height: '250px' }}></div>
                <div
                    style={{
                        width: '100vw',
                        margin: '0 auto',
                        overflowX: 'scroll',
                        overflowY: 'hidden'
                    }}
                >  

                    <div 
                        id="legenddiv"
                        style={{
                            marginTop: '10px',
                            height: '50px',
                            width: legendWidth,
                        }}
                    ></div>
                </div>
            </div>
        </>
    )
}

export default ColumnChartComponent
