import './App.css'
import React, { useEffect, useState } from 'react'
import LineChartComponent from './components/LineChartComponent'
import ColumnChartComponent from './components/ColumnChartComponent'

const App = () => {
  const [isRendering, setIsRendering] = useState(true)
  const [chartConfig, setChartConfig] = useState(window.chart || null)
  const [chartData, setChartData] = useState(window.chart || null)

  const getLineConfig = async () => {
    const res = await fetch('/sample-data/sample-line-config.json')
    const json = await res.json()
    
    return json
  }

  const getLineData = async () => {
    const res = await fetch('/sample-data/sample-line-data.json')
    const json = await res.json()

    return json
  }

  const getColumnConfig = async () => {
    const res = await fetch('/sample-data/sample-column-config.json')
    const json = await res.json()
    
    return json
  }

  const getColumnData = async () => {
    const res = await fetch('/sample-data/sample-column-data.json')
    const json = await res.json()

    return json
  }

  const setupTestData = async () => {
    const config = await getLineConfig()
    const data = await getLineData()

    // const config = await getColumnConfig()
    // const data = await getColumnData()

    if (config && data)
    {
      setChartConfig(config)
      setChartData(data)
    } 
  }

  useEffect(() => {
    // setupTestData() 
    window?.ReactNativeWebView?.postMessage("ready")
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (typeof window.chart.data !== 'undefined' && window.chart.data !== chartData) {
        setChartData(window.chart.data)
      }

      if (typeof window.chart.config !== 'undefined' && window.chart.config !== chartConfig) {
        setChartConfig(window.chart.config)
      }
    }, 200)

    return () => clearInterval(intervalId)
  }, [chartData])

  const handleOnRendered = () => {
    setTimeout(() => {
      window?.ReactNativeWebView?.postMessage("rendered")
      setIsRendering(false)
    }, 200)
  }

  return (
    <>
      { 
        chartConfig?.web_quick_graph?.type === 'column' 
          ? <ColumnChartComponent data={chartData} config={chartConfig} onRendered={handleOnRendered} /> : <></> 
      }
      
      { 
        chartConfig?.web_quick_graph?.type === 'line' 
          ? <LineChartComponent data={chartData} config={chartConfig} onRendered={handleOnRendered} /> : <></> 
      }

      {
          isRendering ?
              <div
                  style={{ 
                      position: 'fixed', 
                      top: 0, 
                      left: 0, 
                      width: '100%', 
                      height: '500px', 
                      textAlign: 'center',
                      paddingTop: '100px',
                      backgroundColor: '#fff',
                      opacity: 1,
                      zIndex: 90 
                  }}
              ></div> : <></>
      }
    </>
  )
}

export default App
